import VueBarcode from "vue-barcode";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./PrintBarcode.template.vue";

@Component({
  mixins: [Template],
  components: {
    VueBarcode
  }
})
export default class PrintableBarcodeComponent extends Vue {
  @Prop() public barcode!: string;
  @Prop() public discountName!: string;

  get barcodeStyles() {
    return {
      textAlign: "center",
      fontSize: 14,
      height: 32,
      width: 1,
      marginTop: 16,
      marginBottom: 16,
      format: `CODE39`,
      tag: "svg",
      font: "Arial"
    };
  }

  public mounted() {
    this.$nextTick(() => this.$emit("readyToPrint"));
  }
}
