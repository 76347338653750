import { Product } from "@/interfaces/product";
import { productService } from "@/services/product.service";
import cloneDeep from "lodash/cloneDeep";
import isEmpty from "lodash/isEmpty";
import { Component, Prop, Vue } from "vue-property-decorator";
import ItemAggregatorDiscountComponent from "../build-blocks/ItemAggregatorDiscount/ItemAggregatorDiscount.component";
import { AddDiscountForm, MockBaseModel } from "../Definitions";
import DoubleWayBindingForm from "../DoubleWayBindingForm.mixin";
import Template from "./DiscountType.template.vue";

@Component({
  mixins: [Template, DoubleWayBindingForm],
  components: {
    itemAggregator: ItemAggregatorDiscountComponent
  }
})
export default class DiscountManagerDiscountTypeComponent extends Vue {
  @Prop({ required: true })
  public incomingModel!: AddDiscountForm;

  public validForm: boolean = false;
  public validBogo: boolean = false;
  public model: AddDiscountForm = cloneDeep(MockBaseModel);

  public attributesTypes = ["FIXED", "PERCENTAGE"];
  public gracePeriodTypes = ["BEFORE", "AFTER", "BEFORE_AND_AFTER"];
  public lapseTypes = ["DAYS", "MONTHS", "YEARS"];

  public products: Product[] = [];
  public async getProducts() {
    this.products = await productService.getAll();
  }

  public requiredRule(value: number) {
    return !!value || this.$t("required_field_error");
  }

  public setValidBogo() {
    this.validBogo =
      this.model.type === "BUY_ONE_GET_ONE_DISCOUNT_TYPE"
        ? !isEmpty(this.model.type_attributes.buy_one_products) &&
          !isEmpty(this.model.type_attributes.get_one_products)
        : true;
  }

  public updateProducts(products: Product[], section: string) {
    if (section === "buy") {
      this.model.type_attributes.buy_one_products = products;
    } else {
      this.model.type_attributes.get_one_products = products;
    }
    this.setValidBogo();
  }

  protected mounted() {
    this.model = this.incomingModel;
    this.getProducts();
    this.setValidBogo();
  }
}
