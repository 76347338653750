import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import ValidRange from "../BasicInfo/validRange/ValidRange.component";
import {
  AddDiscountForm,
  MockBaseModel,
  Range,
  rangeDefault
} from "../Definitions";
import DoubleWayBindingForm from "../DoubleWayBindingForm.mixin";
import Template from "./AutoApply.template.vue";

@Component({
  mixins: [Template, DoubleWayBindingForm],
  components: {
    ValidRange
  }
})
export default class DiscountManagerAutoApplyComponent extends Vue {
  @Prop({ required: true })
  public incomingModel!: AddDiscountForm;

  public model: AddDiscountForm = cloneDeep(MockBaseModel);

  public startTime: string = "";
  public endTime: string = "";

  protected addRange() {
    if (this.model.ranges) {
      this.model.ranges.push(cloneDeep(rangeDefault));
    }
  }

  protected updateRange(modelRange: Range, index: number) {
    if (this.model.ranges) {
      this.model.ranges[index] = modelRange;
    }
  }

  protected get getLimitingRange() {
    return this.model.apply_valid_hours ? this.model.main_range : "";
  }
  protected deleteRange(range: Range, index: number) {
    const ranges = cloneDeep(this.model.ranges);
    if (ranges) {
      ranges.splice(index, 1);
      this.model.ranges = ranges;
    }
  }

  protected mounted() {
    this.model = this.incomingModel;
  }
}
