import { render, staticRenderFns } from "./ItemAggregatorDiscount.template.vue?vue&type=template&id=ad56da60&scoped=true&"
var script = {}
import style0 from "./ItemAggregatorDiscount.template.vue?vue&type=style&index=0&id=ad56da60&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ad56da60",
  null
  
)

export default component.exports