import clone from "lodash/clone";
import indexOf from "lodash/indexOf";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./ItemAggregatorDiscount.template.vue";

interface AggregatorInputOption {
  name: string;
  value: string;
}

@Component({
  mixins: [Template]
})
export default class ItemAggregatorDiscountComponent extends Vue {
  @Prop() public options!: AggregatorInputOption[];
  @Prop() public incomingSelected!: any[];
  @Prop({ default: "type_to_search" }) public placeholder!: string;

  public selectedItems: any[] = [];
  protected initialSet: AggregatorInputOption[] = [];
  protected selectionSearch: string = "";

  @Watch("incomingSelected")
  public onSelectedChange() {
    this.selectedItems = clone(this.incomingSelected);
  }

  @Watch("options")
  public onOptionsChange(newValue: AggregatorInputOption[]) {
    if (this.initialSet.length === 0) {
      this.initialSet = newValue;
    }
  }

  public requiredRule(value: number) {
    return !!value || this.$t("required_field_error");
  }

  public get availableOptions() {
    if (!this.options) {
      return [];
    } else {
      return this.options.filter(
        item => indexOf(this.selectedItems, item) <= -1
      );
    }
  }

  public add(option: any) {
    const isPresent = this.selectedItems.find(
      (item: any) => item.id === option.id
    );
    if (!isPresent) {
      this.selectedItems.push(option);
      this.$emit("input", this.selectedItems);
    }
  }

  public remove(option: any) {
    const i = indexOf(this.selectedItems, option);
    if (i > -1 && option) {
      this.selectedItems.splice(i, 1);
      this.$emit("input", this.selectedItems);
    }
  }

  public mounted() {
    this.selectedItems = this.incomingSelected || [];
    this.$emit("input", this.selectedItems);
  }
}
