import cloneDeep from "lodash/cloneDeep";
import isArray from "lodash/isArray";
import mergeWith from "lodash/mergeWith";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class DoubleWayBindingForm extends Vue {
  public merged: boolean = false;

  @Prop() public incoming: any;
  protected model: any;

  public raiseData() {
    this.$emit("outgoingData", this.model);
  }

  @Watch("model", { deep: true })
  public onModelChange() {
    this.raiseData();
  }

  public mergePersisted() {
    const merged = mergeWith(
      cloneDeep(this.model),
      this.incoming,
      (obj, src) => {
        if (isArray(obj)) {
          obj.concat(src);
        }
      }
    );
    this.model = merged;
    this.merged = true;
  }

  public mounted() {
    this.mergePersisted();
  }

  public collectData(data: any) {
    const merged = mergeWith(
      cloneDeep(this.model),
      cloneDeep(data),
      (obj, src) => {
        if (isArray(obj) && isArray(src)) {
          obj = [];
          return obj.concat(src);
        }
      }
    );
    this.model = merged;
  }
}
