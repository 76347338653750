import { MemberLevel } from "@/interfaces/memberLevel";
import { RetailSettings } from "@/interfaces/retailSettings";
import { EventBus } from "@/internal";
import { memberLevelService } from "@/services/memberLevel.service";
import { fnsFormatDate } from "@/utils/date-fns.utils";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import {
  AddDiscountForm,
  daysOfTheWeek,
  MockBaseModel,
  Range,
  rangeDefault
} from "../Definitions";
import DoubleWayBindingForm from "../DoubleWayBindingForm.mixin";
import PrintableBarcodeComponent from "../PrintBarcode/PrintBarcode.component";
import Template from "./BasicInfo.template.vue";
import ValidRange from "./validRange/ValidRange.component";

@Component({
  mixins: [Template, DoubleWayBindingForm],
  components: {
    ValidRange
  }
})
export default class DiscountManagerBasicInfoComponent extends Vue {
  @Prop({ required: true })
  public incomingModel!: AddDiscountForm;
  public modelReady = false;

  public model: AddDiscountForm = cloneDeep(MockBaseModel);
  public validForm = false;
  public validMainRange = false;
  @Getter("currentRetailSettings", { namespace: "AuthModule" })
  protected retailSettings!: RetailSettings;

  protected limitTypes = [
    { name: "discount_manager.transaction", value: "TRANSACTION" },
    { name: "discount_manager.day", value: "DAY" },
    { name: "discount_manager.week", value: "WEEK" },
    { name: "discount_manager.month", value: "MONTH" },
    { name: "discount_manager.year", value: "YEAR" },
    { name: "discount_manager.lifetime", value: "LIFETIME" }
  ];

  protected discountScopes = [
    { name: "discount_manager.LINE_ITEM", value: "LINE_ITEM" },
    { name: "discount_manager.WHOLE_ORDER", value: "WHOLE_ORDER" }
  ];

  protected memberLevels: MemberLevel[] = [];

  protected rules: {
    [key: string]: Array<(...args: any) => boolean | string>;
  } = {
    barcode_id: [
      (value: string) => {
        if (value) {
          return value.length > 12 ? "Must not have more than 12 digits" : true;
        }
        return true;
      }
    ]
  };

  public requiredRule(value: number) {
    return !!value || this.$t("required_field_error");
  }

  public setRangeValidity() {
    this.validMainRange = this.model.apply_valid_hours
      ? this.hasValidMainRange()
      : true;
  }

  protected addRange() {
    if (this.model.ranges) {
      this.model.ranges.push({ ...rangeDefault });
    }
  }

  protected updateMainRange(mainRange: Range) {
    this.model.main_range = mainRange;
    this.setRangeValidity();
  }

  protected hasValidMainRange() {
    let hasADay = false;
    if (this.model.main_range) {
      Object.keys(this.model.main_range).forEach(key => {
        if (daysOfTheWeek.includes(key)) {
          if (this.model.main_range && this.model.main_range[key]) {
            hasADay = true;
          }
        }
      });
    }
    return hasADay;
  }

  public get limitValidation() {
    if (this.model.apply_limit) {
      return this.model.apply_limit_value > 0 && this.model.apply_limit_type;
    } else {
      return true;
    }
  }

  public get nextStepValidation() {
    return !this.validForm || !this.validMainRange || !this.limitValidation;
  }

  protected async getMemberLevels() {
    this.memberLevels = await memberLevelService.get({ no_pagination: true });
  }

  protected generateBarcode() {
    setTimeout(() => {
      const uniq = String(
        Math.floor(Date.now()) * Math.floor(Math.random() * 1000)
      );
      const reduced = uniq.slice(0, 6);
      this.model.external_barcode = reduced;
    }, 700);
  }

  protected mounted() {
    this.getMemberLevels();
    this.model = this.incomingModel;
    this.modelReady = true;
    if (!this.model.id) {
      this.model.post_tax = !this.retailSettings!.retail_pre_tax_pricing;
    }
    if (
      this.model.main_range &&
      !isEqual(this.model.main_range, rangeDefault)
    ) {
      this.model.apply_valid_hours = true;
    }
    this.setRangeValidity();
  }

  protected setValidHours() {
    if (
      this.model.apply_valid_hours &&
      isEqual(this.model.main_range, rangeDefault)
    ) {
      this.model.main_range!.start_date = fnsFormatDate(
        new Date(),
        "MM-DD-YYYY HH:mm:ss"
      );
    }
    this.setRangeValidity();
  }
  protected printBarcode() {
    EventBus.$emit("print", {
      component: PrintableBarcodeComponent,
      props: {
        barcode: "DISC".concat(this.model.external_barcode),
        discountName: this.model.name
      }
    });
  }

  protected async validateBarcodeBeforeNext() {
    if (!this.model.external_barcode) {
      await this.generateBarcode();
    }
    this.$emit("nextStep");
  }
}
