import { EventBus } from "@/internal";
import cloneDeep from "lodash/cloneDeep";
import findIndex from "lodash/findIndex";
import isEqual from "lodash/isEqual";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./ItemSeparator.template.vue";

export interface SeparatorModel {
  leftSide: any[];
  rightSide: any[];
  [key: string]: any;
}
export const defaultSeparatorModel: SeparatorModel = {
  leftSide: [],
  rightSide: []
};

@Component({
  mixins: [Template]
})
export default class ItemSeparator extends Vue {
  @Prop({ default: () => [], required: true })
  public incomingItems!: any[];
  @Prop({ default: () => [] }) public rightSideIncoming!: any[];
  @Prop() public leftHeader!: string;
  @Prop() public rightHeader!: string;

  public model: SeparatorModel = { ...defaultSeparatorModel };
  public lastItemsSelected: {
    leftSide: number | null;
    rightSide: number | null;
    [key: string]: any;
  } = { leftSide: null, rightSide: null };

  public sendFromTo(from: string, to: string) {
    const itemsToPush = [];
    for (let i = this.model[from].length - 1; i >= 0; i--) {
      const item = this.model[from][i];
      if (item.isSelected) {
        item.isSelected = false;
        itemsToPush.push(item);
        this.model[from].splice(i, 1);
      }
    }
    const newModel = [...cloneDeep(this.model[to]), ...itemsToPush.reverse()];
    this.model[to] = newModel;
    this.$emit("itemUpdate", this.model);
  }

  public selectItem(item: any, side: string, event: MouseEvent) {
    if (item.isSelected) {
      this.$set(item, "isSelected", false);
      return;
    }
    const currentItemIndex = findIndex(
      this.model[side],
      (element: any) => element.sku === item.sku
    );
    if (event.shiftKey) {
      const lastItemIndex = this.lastItemsSelected[side];
      const range =
        lastItemIndex > currentItemIndex
          ? [currentItemIndex, lastItemIndex]
          : [lastItemIndex, currentItemIndex];
      for (let i = range[0]; i <= range[1]; i++) {
        this.$set(this.model[side][i], "isSelected", true);
      }
    } else {
      this.$set(item, "isSelected", true);
      this.lastItemsSelected[side] = currentItemIndex;
    }
  }

  public setSides() {
    this.model.leftSide = this.incomingItems;
    if (this.rightSideIncoming.length) {
      this.model.rightSide = this.rightSideIncoming;
    }
    this.compareItemsIn();
    this.$emit("itemUpdate", this.model);
  }

  @Watch("incomingItems")
  protected setSidesHandler(newVal: any, oldVal: any) {
    if (!isEqual(newVal, oldVal)) {
      this.setSides();
    }
  }

  protected mounted() {
    this.setSides();
    EventBus.$on("setNewItems", () => {
      this.model.rightSide = [];
    });
  }

  private compareItemsIn() {
    // this makes sure the right side is a subset of items received
    // and that any item in the right side is taken from the left side
    for (let i = this.model.rightSide.length - 1; i >= 0; i--) {
      const item = this.model.rightSide[i];
      const inLeftSide = findIndex(this.model.leftSide, element => {
        return element.sku === item.sku;
      });
      if (inLeftSide >= 0) {
        this.model.leftSide.splice(inLeftSide, 1);
      }
    }
  }
}
