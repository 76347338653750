import { MemberLevel } from "@/interfaces/memberLevel";
import { Product } from "@/interfaces/product";
import { EventBus, pusherEvents } from "@/internal";
import { discountManagerService } from "@/services/discountManager.service";
import { Callback, PageNavAction } from "@/types/types";
import cloneDeep from "lodash/cloneDeep";
import forEach from "lodash/forEach";
import isEqual from "lodash/isEqual";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import DiscountManagerAutoApplyComponent from "./AutoApply/AutoApply.component";
import DiscountManagerBasicInfoComponent from "./BasicInfo/BasicInfo.component";
import {
  AddDiscountForm,
  daysOfTheWeek,
  MockBaseModel,
  Range,
  rangeDefault
} from "./Definitions";
import Template from "./DiscountManagerAdd.template.vue";
import DiscountManagerDiscountTypeComponent from "./DiscountType/DiscountType.component";
import DiscountManagerEligibleProductsComponent from "./EligibleProducts/EligibleProducts.component";

const namespace = "DiscountManagerModule";

@Component({
  mixins: [Template],
  components: {
    "basic-info-component": DiscountManagerBasicInfoComponent,
    "discount-type-component": DiscountManagerDiscountTypeComponent,
    "eligible-products-component": DiscountManagerEligibleProductsComponent,
    "auto-apply-component": DiscountManagerAutoApplyComponent
  },
  inject: ["$changes"]
})
export default class DiscountManagerAddComponent extends Vue {
  // LAYOUT ACTIONS
  public get actionLabel() {
    return this.currentStep >= this.steps.length
      ? this.$t("submit")
      : this.$t("next");
  }
  public model: AddDiscountForm = cloneDeep(MockBaseModel);
  public modelReady: boolean = false;

  public currentStep = 1;
  public steps = [
    "basic-info-component",
    "discount-type-component",
    "eligible-products-component",
    "auto-apply-component"
  ];

  @Getter("loading", { namespace })
  public loading!: boolean;

  @Action("saveDiscount", { namespace })
  protected saveDiscount!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  protected setPageNav!: PageNavAction;
  @Action("errorRedirect", { namespace: "RouterModule" })
  protected errorRedirect!: Callback;

  public mounted() {
    this.setPageNav({
      title: "discount_manager.title",
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-times",
            action: this.cancel
          }
        ]
      }
    });
    this.getFormData();
    this.$changes.watch(
      [
        pusherEvents.percentageDiscountTouched,
        pusherEvents.fixedDiscountTouched
      ],
      () => {
        this.getFormData();
      }
    );
  }

  public getElegibleProduct() {
    if (this.model.eligible_products) {
      return this.model.eligible_products.map(
        (product: Product) => product.sku
      );
    }
    return [];
  }

  public getBogo() {
    const typeAttributes: {
      buy_one_products: string[];
      get_one_products: string[];
    } = { buy_one_products: [], get_one_products: [] };

    if (this.model.type_attributes.buy_one_products) {
      typeAttributes.buy_one_products = this.model.type_attributes.buy_one_products.map(
        (product: Product) => product.sku
      );
    }
    if (this.model.type_attributes.get_one_products) {
      typeAttributes.get_one_products = this.model.type_attributes.get_one_products.map(
        (product: Product) => product.sku
      );
    }
    return typeAttributes;
  }

  public getRanges() {
    let ranges: Range[] = [];
    if (this.model.ranges) {
      ranges = [...this.model.ranges];

      let allValidRanges = 0;
      // at leat a day must be true in the autoapply ranges;
      forEach(ranges, (range: Range) => {
        let hasADay = false;
        Object.keys(range).forEach(key => {
          if (daysOfTheWeek.includes(key)) {
            if (range[key]) {
              hasADay = true;
            }
          }
        });
        if (hasADay) {
          allValidRanges++;
        }
      });

      if (allValidRanges !== ranges.length) {
        EventBus.$emit("requiredDay");
        return null;
      }

      // make empty ranges valid
      ranges = ranges.map((range: Range) => {
        if (!range.from) {
          range.from = "00:00:00";
        }
        if (!range.to) {
          range.to = "23:59:59";
        }
        return range;
      });
    }
    return ranges;
  }

  public getMemberLevels() {
    let memberLevels: MemberLevel[] = [];
    if (this.model.apply_member_levels) {
      memberLevels = [...this.model.member_levels];
    }
    return memberLevels;
  }

  public submit() {
    const mappedProducts = this.getElegibleProduct();
    const typeAttributes = this.getBogo();
    this.model.ranges = (this.model.auto_apply && this.model.ranges) || [];
    const ranges = this.getRanges();

    if (ranges === null) {
      return;
    }
    const memberLevels = this.getMemberLevels();

    if (!this.model.apply_valid_hours) {
      this.model.main_range = null;
    }

    if (this.model.main_range) {
      let hasADay = false;
      Object.keys(this.model.main_range).forEach(key => {
        if (daysOfTheWeek.includes(key)) {
          if (this.model.main_range && this.model.main_range[key]) {
            hasADay = true;
          }
        }
      });
      if (!hasADay) {
        EventBus.$emit("requiredDay");
        return null;
      }
    }

    this.saveDiscount({
      ...this.model,
      eligible_products: mappedProducts,
      type_attributes: { ...this.model.type_attributes, ...typeAttributes },
      member_levels: memberLevels,
      ranges
    });
  }

  public cancel() {
    this.$router.push({ name: "discount-manager" });
  }

  public nextStep() {
    this.currentStep <= this.steps.length - 1
      ? this.currentStep++
      : this.submit();
    if (
      this.currentStep === 3 &&
      this.model.type === "BUY_ONE_GET_ONE_DISCOUNT_TYPE"
    ) {
      this.currentStep++;
    }
    EventBus.$emit("changedStep");
  }
  public prevStep() {
    this.currentStep = Math.max(0, this.currentStep - 1);
    if (
      this.currentStep === 3 &&
      this.model.type === "BUY_ONE_GET_ONE_DISCOUNT_TYPE"
    ) {
      this.currentStep--;
    }
    EventBus.$emit("changedStep");
  }

  private async getFormData() {
    if (this.$route.params.id) {
      try {
        const response = await discountManagerService.getDiscount(
          +this.$route.params.id
        );
        this.model = { ...response };
        this.modelReady = true;
      } catch (error) {
        this.errorRedirect({
          location: { name: "discount-manager" },
          error
        });
      }
    }
    this.modelReady = true;
    if (this.model.member_levels.length) {
      this.model.apply_member_levels = true;
    }
    if (
      this.model.main_range &&
      !isEqual(this.model.main_range, rangeDefault)
    ) {
      this.model.apply_valid_hours = true;
    }
  }
}
