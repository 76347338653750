import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./EligibleProducts.template.vue";

import { BatchType } from "@/interfaces/batchType";
import { Brand } from "@/interfaces/brand";
import { Product } from "@/interfaces/product";
import { ProductCategory } from "@/interfaces/productCategoy";
import { EventBus } from "@/internal";
import { batchTypeService } from "@/services/batchType.service";
import { brandService } from "@/services/brand.service";
import { productService } from "@/services/product.service";
import { productCategoryService } from "@/services/productCategory.service";
import cloneDeep from "lodash/cloneDeep";
import ItemSeparator, {
  SeparatorModel
} from "../build-blocks/ItemSeparator/ItemSeparator.component";
import { AddDiscountForm, MockBaseModel } from "../Definitions";
import DoubleWayBindingForm from "../DoubleWayBindingForm.mixin";

@Component({
  mixins: [Template, DoubleWayBindingForm],
  components: {
    ItemSeparator
  }
})
export default class DiscountManagerEligibleProductsComponent extends Vue {
  @Prop({ required: true })
  public incomingModel!: AddDiscountForm;

  public model: AddDiscountForm = cloneDeep(MockBaseModel);

  public products: Product[] = [];
  public productQuery = {
    batch_type_id_is_in: null,
    product_category_id_is_in: null,
    brand_id_is_in: null,
    marijuana_eq: null,
    requires_weighing_eq: null
  };

  public batchTypes: BatchType[] = [];
  public productCategories: ProductCategory[] = [];
  public brands: Brand[] = [];
  public cannabisOptions: any[] = [
    { name: "Cannabis", value: 1 },
    { name: "Non-Cannabis", value: 0 }
  ];
  public weighableOptions: any[] = [
    { name: "Weighable", value: 1 },
    { name: "Non-Weighable", value: 0 }
  ];

  protected radioOptions = [
    {
      label: "discount_manager.all_products",
      value: 1
    },
    {
      label: "discount_manager.product_eligibility",
      value: 0
    }
  ];

  public setSides(separatorResult: SeparatorModel) {
    this.model.eligible_products = [...separatorResult.leftSide];
  }

  public async getProducts() {
    const query: { [key: string]: number[] | null } = {};
    Object.keys(this.productQuery).forEach((key, index) => {
      query[`q[${key}]`] = Object.values(this.productQuery)[index];
    });
    this.products = await productService.getAll(query);
    EventBus.$emit("setNewItems");
  }

  public async getCategories() {
    this.productCategories = await productCategoryService.getAllCategories();
  }
  public async getBrands() {
    this.brands = await brandService.get({ no_pagination: true });
  }
  public async getBatchTypes() {
    this.batchTypes = await batchTypeService.get({ no_pagination: true });
  }

  @Watch("model.eligible_products", { deep: true })
  public setEligibles(newV: Product[], oldV: Product[]) {
    if (newV !== oldV) {
      if (this.model.eligible_products && this.model.eligible_products.length) {
        this.products = this.model.eligible_products;
      }
    }
  }

  public mounted() {
    this.getCategories();
    this.getBrands();
    this.getBatchTypes();
    this.model = this.incomingModel;
  }
}
